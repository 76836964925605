import React, { useEffect, useRef } from 'react';
import { FormControl } from 'react-bootstrap';
import { AiOutlineSearch } from 'react-icons/ai'
import { IoMdClose } from 'react-icons/io';
import { useTranslation } from "react-i18next";

const SearchInput = (props) => {

    const {
        searchLang,
        closeLangList,
        searchText
    } = props;

    const { t, i18n } = useTranslation();

    const focusTextInput = useRef(null)

    useEffect(() => {
        if(focusTextInput.current != null) {
            focusTextInput.current.focus()
            console.log('focus cursor')
        }
    }, [])

    return (
        // <div className='input-search-group'>
        //     <div className='input-search-group-wrapper ml-3 mr-3'>
        //         <div className="input-group">
        //             <span className="input-group-btn pr-3" >
        //                 <AiOutlineSearch className='pl-3' size={ 20 } />
        //             </span>
        //             <FormControl
        //                 className='input-text'
        //                 aria-describedby="basic-addon1"
        //                 placeholder={ t('translateText.search_text') }
        //                 onChange={(e) => { searchLang(e); }}
        //                 value={ searchText }
        //                 style={{ border: '0px' }}
        //                 ref={ focusTextInput }
        //             />
        //             <span className="input-group-btn pr-3 cursor-pointer" onClick={ closeLangList }>
        //                 <IoMdClose size={ 20 } />
        //             </span>
        //         </div>
        //     </div>
        // </div>
        <div className='input-search-group-wrapper'>
            <span className="content-center">
                <AiOutlineSearch size={ 20 } />
            </span>
            <FormControl
                className='input-text'
                aria-describedby="basic-addon1"
                placeholder={ t('translateText.search_text') }
                onChange={(e) => { searchLang(e); }}
                value={ searchText }
                ref={ focusTextInput }
            />
            <span className="cursor-pointer content-center" onClick={ closeLangList }>
                <IoMdClose size={ 20 } />
            </span>
        </div>
    )
}

export default SearchInput