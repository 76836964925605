import uuid from "uuid";

import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { InputGroup, FormControl, Button } from "react-bootstrap";
import { IoIosSend } from 'react-icons/io'
import { ThreeBounce } from 'better-react-spinkit'
import { setAiChatSelectedSpace, setAiChatSelectedSpaceChatHistory, setAiChatSpace, setAiChatSpaceList, setNewChatsSpace } from "../../../modules/aiChat";
import { setLoadingWork, setLoadingAnswering } from "../../../modules/loading";
import { setSelectedPrompt } from "../../../modules/aiChat";
import { IoIosClose, IoIosMenu } from 'react-icons/io';
import { setSummaryDocSelectedHistory } from "../../../modules/summaryDoc";
import { setFetchController } from "../../../modules/apps";
import { useTranslation } from "react-i18next"; "../../../langs/i18n";


const ChatInputGroup = () => {

    const dispatch = useDispatch();

    const focusSummaryInput = useRef(null)

    const [userMessage, setUserMessage] = useState("");

    const uiSmallAiBox = useSelector(state => state.ui.uiSmallAiBox);
    const userToken = useSelector(state => state.user.userToken);
    const orgCode = useSelector(state => state.organization.orgCode);
    const aiChatSelectedSpace = useSelector(state => state.aiChat.aiChatSelectedSpace);
    const aiChatSelectedSpaceChatHistory = useSelector(state => state.aiChat.aiChatSelectedSpaceChatHistory);
    const loadingSpace = useSelector(state => state.loading.loadingSpace);
    const loadingWork = useSelector(state => state.loading.loadingWork);
    const selectedPrompt = useSelector(state => state.aiChat.selectedPrompt);
    const uiHideSidebar = useSelector(state => state.ui.uiHideSidebar);

    const summaryDocSelectedSpace = useSelector(state => state.summaryDoc.summaryDocSelectedSpace);
    const summaryDocSelectedSpaceChatHistory = useSelector(state => state.summaryDoc.summaryDocSelectedSpaceChatHistory);

    const { t, i18n } = useTranslation();

    /*
    useEffect(() => {
        if(loadingWork == false) {
            console.log(focusSummaryInput.current)
            console.log('focus cursor')
        }
    }, [loadingWork, focusSummaryInput])
    */

    useEffect(() => {
        // init input focus
        if(loadingSpace == false) {
            if(focusSummaryInput.current != null) {
                focusSummaryInput.current.focus()
                console.log('focus cursor')
            }
        }
    }, [loadingSpace])

    useEffect(() => {
        if(loadingWork == false && summaryDocSelectedSpace != false && loadingSpace == false) {
            if(focusSummaryInput.current != null) {
                focusSummaryInput.current.focus()
                console.log('focus cursor')
            }
        }
    }, [loadingWork])

    function onKeyUp(e) {
        if (e.keyCode === 13) {
            // setUserMessage(e.target.value.trim())
            sendUserMessage();
        }
    }

    function sendUserMessage(){
        // 답변이 생성될때 추가적인 메세지 전송 불가
        if(!loadingWork && userMessage.trim() != "") {

            var controller = new AbortController();
            dispatch( setFetchController(controller) );
            const signal = controller.signal;

            console.log(userMessage)
            setUserMessage(""); // init input value
            dispatch(setLoadingWork(true)); // 채팅을 보냈을 때, 로딩값 true로 설정
            dispatch(setLoadingAnswering(true));
            var data = {
                "orgCode" : orgCode,
                "uToken" : userToken,
                "message" : userMessage.trim(), // 채팅 앞, 뒤 공백 제거
                "type" : "summaryDoc",
                "userMsgId" : uuid.v4(),
                "space_id" : summaryDocSelectedSpace.space_id
            };

            var requestData = JSON.stringify(data);
            console.log("data : ", JSON.parse(requestData))

            var tmpHistory = summaryDocSelectedSpaceChatHistory;
            tmpHistory.push({
                "content" : data['message'],
                "role" : "user",
                "msgId" : data['userMsgId']
            })
            
            tmpHistory = [...tmpHistory]
            
            console.log(tmpHistory)
            dispatch(setSummaryDocSelectedHistory(tmpHistory))
            
            // "https://aichat.coursemos.kr/v2/summaryChat" // prev
            fetch("https://summarychat.coursemos.kr/v2/summaryChat", { // update
                signal,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    },
                    body: requestData
            })
            .then(response => {
                console.log(response.headers.get('content-type'))
                if (response.status === 200 && response.headers.get('content-type') === 'text/event-stream') {



                    const reader = response.body.getReader();
                    const decodeText = buffer => {
                    const decoder = new TextDecoder();
                    return decoder.decode(buffer, { stream: true });
                    };
                    
                    const readChunk = () => {
                    return reader.read()
                        .then(({ done, value }) => {
                        if (done) {
                            console.log('Streaming completed');
                            dispatch(setLoadingWork(false)); // 채팅을 보냈을 때, 로딩값 true로 설정
                            dispatch(setLoadingAnswering(false));
                            return;
                        }
        
                        const chunk = decodeText(value);
                        console.log( chunk ) ;
                        // "}{"" 패턴을 기준으로 JSON 객체들을 분리
                        const jsonObjects = chunk.split('}{');

                        // 각 JSON 객체 파싱 후 출력
                        jsonObjects.forEach((jsonObject, index) => { 
                        // 맨 앞과 맨 뒤에 중괄호가 없는 경우, 중괄호를 추가하여 유효한 JSON 형식으로 만듦
                        if (index !== 0) {
                            jsonObject = '{' + jsonObject;
                        }
                        if (index !== jsonObjects.length - 1) {
                            jsonObject = jsonObject + '}';
                        }
                        
                        try {
                            const parsedObject = JSON.parse(jsonObject);
                            //console.log(parsedObject);
                            switch(parsedObject.state){
                                case "start":

                                    //Is New 가 있을 수 없음 무조건 선택 후 채팅이됨

                                break;
                                
                                case "streaming":
                                    console.log(parsedObject.content)
                                    const entry = Object.entries(tmpHistory).find(([key, value]) => value.msgId === parsedObject['assistantMsgId']);
                                    if (entry) {
                                        const [key, value] = entry;
                                        value.content += parsedObject['content'];
                                        tmpHistory = [...tmpHistory]
                                        
                                        dispatch(setSummaryDocSelectedHistory(tmpHistory))                                  
                                        console.log("content가 성공적으로 수정되었습니다. : ", value);
                                    } else {
                                        console.log("해당 msgId를 가진 데이터를 찾을 수 없습니다.");
                                        tmpHistory.push({
                                            "content" : parsedObject['content'],
                                            "role" : parsedObject['role'],
                                            "msgId" : parsedObject['assistantMsgId']
                                        })
                                        
                                        tmpHistory = [...tmpHistory]
                                        
                                        dispatch(setSummaryDocSelectedHistory(tmpHistory))  
                                    }

                                break;
                                case "end":
                                    console.log("END DATA : ", parsedObject)                                       
                                break;


                            }
                            
                            
                        } catch (error) {
                            console.error("JSON 파싱 에러:", error);
                            dispatch(setLoadingAnswering(false));
                        }
                        });

                        //var chunkData = JSON.parse(chunk);
                        //console.log(chunkData.content);
                        // 원하는 처리를 수행합니다.
                        return readChunk(); // 다음 chunk를 읽습니다.
                        });
                    };
        
                    return readChunk(); // 첫 번째 chunk를 읽습니다.
                } else {
                    dispatch(setLoadingWork(false)); // 채팅을 보냈을 때, 로딩값 true로 설정
                    console.error('Invalid response:', response.status);
                }
                })
            .catch(error => {
                dispatch(setLoadingWork(false)); // 채팅을 보냈을 때, 로딩값 true로 설정
                console.error('Fetch error:', error);
            });
            
        }
    }

    return (
        <>
            {
                !loadingSpace && (
                    <div className='wrapper-bottom-inputGroup fr' style={ ( !uiSmallAiBox && uiHideSidebar ) ? { width: '100%' } : {} }>
                        {
                            selectedPrompt != false &&
                            <>
                                <div className="box-selected-prompt">
                                    <div className='label-prompt fl'>
                                        {selectedPrompt.label} - {selectedPrompt.desc}
                                    </div>
                                    <div className=' fr' onClick={() => { dispatch(setSelectedPrompt(false)) }}>
                                        <IoIosClose size={"1.5em"} />
                                    </div>          
                                    <div className='cr'></div>                           
                                </div>
                            </>
                        }
                        <div className="input-group">
                            <FormControl
                                className={ uiSmallAiBox ? "input-round chat-input-size" : "chat-input-size" }
                                size="lg"
                                placeholder={ t("aichat.input") }
                                aria-label={ t("aichat.input") }
                                aria-describedby="basic-addon2"
                                // onChange={(e)=> {setUserMessage(e.target.value.trim()); console.log(userMessage) }}
                                onChange={(e)=> setUserMessage(e.target.value)}
                                // onKeyPress={onKeyUp}
                                onKeyDown={onKeyUp}
                                value={userMessage}
                                disabled={ loadingWork ? true : false }
                                ref={ focusSummaryInput }
                            />
                            <span className="input-group-btn">
                                { loadingWork != false ? 
                                    <Button className='btn-send-msg' variant='default' disabled>
                                        <ThreeBounce color={ "#808080" }/>
                                    </Button>
                                    : 
                                    <Button className='btn-send-msg' variant="default" onClick={() => sendUserMessage()}>
                                        <IoIosSend/>
                                    </Button>
                                    
                                }
                            </span>
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default ChatInputGroup;