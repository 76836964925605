
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { TbWindowMinimize } from 'react-icons/tb';
import { AiOutlineRight } from 'react-icons/ai'
import { Cookies } from 'react-cookie';
import axios from 'axios';
import { TfiMenuAlt } from 'react-icons/tfi'
import { HiOutlineArrowsExpand } from 'react-icons/hi'
import { IoIosClose } from 'react-icons/io';
import { BiArrowBack } from 'react-icons/bi'
import { AiOutlineSetting } from 'react-icons/ai'
import { MdOutlineClose } from "react-icons/md";

import {  BtnCsmsAi } from './app/components/_commons';
import { setOpts, setOrgCode } from './app/modules/organization';
import { setUserToken } from './app/modules/user';
import { setSelectedApp } from './app/modules/status';
import { UiChat, UiInterviewPrep, UiMain, UiSpeakingTutor, UiSummaryDoc, UiTranslateDoc, UiTranslateText, UiWritingTutor } from './app/components/ui';
import { setUiModalAiBox, setUiShowAiBox, setUiSmallAiBox, setUiStateBlock, setUiStateLeftSidebar } from './app/modules/ui';


import './resources/assets/styles/sass/ModalAiBox.scss';
import './resources/assets/styles/sass/SmallAiBox.scss';
import UiSetting from './app/components/ui/uiSetting';
import { setSelectedSetting, setUserOpts } from './app/modules/setting';
import { useTranslation } from 'react-i18next';
import i18n from './app/langs/i18n';
import i18next from 'i18next';

const Common = () => {
    const dispatch = useDispatch();

    const { t } = useTranslation();

    const uiShowAiBox = useSelector(state => state.ui.uiShowAiBox);
    const uiSmallAiBox = useSelector(state => state.ui.uiSmallAiBox);
    const uiModalAiBox = useSelector(state => state.ui.uiModalAiBox);
    const userToken = useSelector(state => state.user.userToken);
    const selectedApp = useSelector(state => state.status.selectedApp);
    const orgCode = useSelector(state => state.organization.orgCode);
    const selectedSetting = useSelector(state => state.setting.selectedSetting)
    const userOpts = useSelector(state => state.setting.userOpts)

    const [stateModalAiBox, setStateModalAiBox] = useState(false);

    useEffect(() => {
        var userLang = $("html").attr('lang');
        console.log("USERLANg Code : ",userLang);
        checkAuth();
    },[]);

    // useEffect(() => {
    //     function handleResize() {
    //         if(window.innerWidth <= 768) { // 786px
    //             dispatch(setUiModalAiBox(false))
    //             dispatch(setUiSmallAiBox(true)) // less 768px size to small ai box
    //         }
    //     }
    //     window.addEventListener('resize', handleResize);

    //     return () => {
    //         window.removeEventListener('resize', handleResize);
    //     }
    // }, []);

    useEffect(() => {
        if(typeof userOpts['BASE_MY_LANG'] != 'undefined') {
            switch(userOpts['BASE_MY_LANG']) {
                case 'ko':
                    i18n.changeLanguage('ko')
                    break;
                case 'en-gb':
                    i18n.changeLanguage('enGB')
                    break;
                case 'en-us':
                    i18n.changeLanguage('enUS')
                    break;
                case 'ja':
                    i18n.changeLanguage('ja')
                    break;
                case 'zh-cn':
                    i18n.changeLanguage('zhCN')
                    break;
                case 'es':
                    i18n.changeLanguage('es')
                    break;
                case 'vi':
                    i18n.changeLanguage('vi')
                    break;
                default:
                    i18n.changeLanguage(userOpts['BASE_MY_LANG'])
                    break;
            }

        } else {
            switch(navigator.language) {
                case 'ko':
                case 'ko-KP':
                case 'ko-KR':
                    i18n.changeLanguage('ko')
                    break;
                case 'en-US':
                    i18n.changeLanguage('enUS')
                    break;
                case 'en-GB':
                    i18n.changeLanguage('enGB')
                    break;
                case 'ja':
                case 'ja-JP':
                    i18n.changeLanguage('ja')
                    break;
                case 'zh':
                case 'zh-Hans-CN':
                    i18n.changeLanguage('zhCN')
                    break;
                case 'es':
                    i18n.changeLanguage('es')
                    break;
                case 'vi':
                case 'vi-VN':
                    i18n.changeLanguage('vi')
                    break;
                default:
                    i18n.changeLanguage('enGB')
                    break;
            }
        }
    }, [userOpts])

    function checkAuth(){ // Check Auth from Moodle
        var data = JSON.stringify({});
        var config = {
            method: 'post',
            url: '/local/csmsai/apis/getToken.php',
            headers: { 
              'Content-Type': 'application/json'
            },
            data : data
        };
      

        axios(config)
        .then( (response) => {
            if( response.data.code == 100){
              
                var orgCode = response.data.orgCode;
                var uToken = response.data.token;
              
                dispatch(setUserToken(uToken))
                dispatch(setOrgCode(orgCode))

                checkAuthToken(orgCode, uToken); // get CSMSAI OPT DATA
                checkSettingOpts(orgCode, uToken); // get CSMSAI Setting OPT DATA
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }

    function checkAuthToken(orgCode: string, uToken: string) {
        var data = JSON.stringify({
            "orgCode" : orgCode,
            "uToken" : uToken,
        });
        var config = {
            method: 'post',
            url:  API_URL + '/' + API_VERSION + '/common/checkAuthToken',
            headers: { 
                'Content-Type': 'application/json'
            },
            data : data
        };

      axios(config)
      .then( (response) => {
          if( response.data.code == 100){
              let optsData = response.data.opts;

              (Object.keys(optsData)).map((item, idx) => {
                  if( !(item).includes("OPT_CSMSAI") && !(item).includes("SERVICE_TRANSLATOR") ) {
                      delete optsData[item];
                  }
              })

              dispatch( setOpts(optsData) );
          }
      })
      .catch((error) => {
          console.log(error);
      });
    }

    function checkSettingOpts(orgCode: string, uToken: string) {
        var data = JSON.stringify({
            "orgCode" : orgCode,
            "uToken" : uToken,
        });
        var config = {
            method: 'post',
            url:  API_URL + '/' + API_VERSION + '/common/getOpts',
            headers: { 
                'Content-Type': 'application/json'
            },
            data : data
        };

        axios(config)
        .then((response) => {
            if( response.data.code == 100){

                console.log(response.data);
                const optsData: any = {};
                (response.data.opts).map((optsLists, idx: number) => {
                    Object.keys(optsLists).map((item, idx2) => {
                        console.log(item, idx2)
                        optsData[item] = optsLists[item]
                    });

                })

                console.log(optsData)
                dispatch(setUserOpts(optsData))
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }

    function getEventState() {
        // show event logo / event guide

        return false;

        /*
        const eventTime = 1700096400;
        const currentTime = new Date().getTime();

        if(orgCode == "kangwon" || orgCode == "gwlrs" || orgCode == "inu_test" ) {
            if(currentTime >= (eventTime * 1000)) {
                return false;
            } else {
                return true;
            }
        } else {
            return false
        }
        */
    }


    function CurrentApp(){
        switch(selectedApp){
            case "MAIN":
                return <UiMain getEventState={ getEventState() }/>;
            case "TranslateDoc":
                return <UiTranslateDoc/>;
            case "TranslateText":
                return <UiTranslateText/>;
            case "SummaryDoc":
                return <UiSummaryDoc/>;
            case "CHAT":
                return <UiChat/>;
            case "SETTING":
                return <UiSetting />;
            case "InterviewPrep":
                return <UiInterviewPrep />;
            case "WritingTutor":
                return <UiWritingTutor />;
            case "SpeakingTutor":
                return <UiSpeakingTutor />;
            default:
                return <UiMain getEventState={ getEventState() }/>;              
        }
    }

    function CurrentIcon() {

        if(IS_DEV) {
            switch(selectedApp){
                case "TranslateDoc":
                    return (
                        <>
                            <span className='d-inline-block'>{ t("common.translateDoc") }</span>
                            <AiOutlineSetting size={ 20 } className='ml-2 cursor-pointer' onClick={() => { dispatch(setSelectedSetting("TranslateDoc")); dispatch(setSelectedApp("SETTING")) }} />
                        </>
                    );
                case "TranslateText":
                    return (
                        <>
                            <span className='d-inline-block'>{ t("common.translateText") }</span>
                            <AiOutlineSetting size={ 20 } className='ml-2 cursor-pointer' onClick={() => { dispatch(setSelectedSetting("TranslateText")); dispatch(setSelectedApp("SETTING")) }} />
                        </>
                    );
                case "SummaryDoc":
                return (
                    <>
                        <span className='d-inline-block'>{ t("common.summaryDoc") }</span>
                        <AiOutlineSetting size={ 20 } className='ml-2 cursor-pointer' onClick={() => { dispatch(setSelectedSetting("SummaryDoc")); dispatch(setSelectedApp("SETTING")) }} />
                    </>
                );
                case "CHAT":
                    return (
                        <>
                            <span className='d-inline-block'>{ t("common.chat") }</span>
                            <AiOutlineSetting size={ 20 } className='ml-2 cursor-pointer' onClick={() => { dispatch(setSelectedSetting("CHAT")); dispatch(setSelectedApp("SETTING")) }} />
                        </>
                    );
                case "SETTING":
                    // return <>설정</>;
                    return (
                        <>
                            <SelectedTextLink />
                            <span className='d-inline-block'>{ t("common.setup")}</span>
                        </>
                    )
                case "InterviewPrep":
                    return (
                        <>
                            <span className='d-inline-block'>{ t("common.interviewPrep") }</span>
                            <AiOutlineSetting size={ 20 } className='ml-2 cursor-pointer' onClick={() => { dispatch(setSelectedSetting("InterviewPrep")); dispatch(setSelectedApp("SETTING")) }} />
                        </>
                    );
                case "WritingTutor":
                    return (
                        <>
                            <span className='d-inline-block'>{ t("common.writingTutor") }</span>
                            <AiOutlineSetting size={ 20 } className='ml-2 cursor-pointer' onClick={() => { dispatch(setSelectedSetting("WritingTutor")); dispatch(setSelectedApp("SETTING")) }} />
                        </>
                    );
                case "SpeakingTutor":
                    return (
                        <>
                            <span className='d-inline-block'>{ t("common.speakingTutor") }</span>
                            <AiOutlineSetting size={ 20 } className='ml-2 cursor-pointer' onClick={() => { dispatch(setSelectedSetting("SpeakingTutor")); dispatch(setSelectedApp("SETTING")) }} />
                        </>
                    );
                default:
                    return <></>;
            }
        } else {

            const settingMenu = "TranslateText";

            switch(selectedApp){
                case "TranslateDoc":
                    return (
                        <>
                            <span className='d-inline-block'>{ t("common.translateDoc") }</span>
                            <AiOutlineSetting size={ 20 } className='ml-2 cursor-pointer' onClick={() => { dispatch(setSelectedSetting(settingMenu)); dispatch(setSelectedApp("SETTING")) }} />
                        </>
                    );
                case "TranslateText":
                    return (
                        <>
                            <span className='d-inline-block'>{ t("common.translateText") }</span>
                            <AiOutlineSetting size={ 20 } className='ml-2 cursor-pointer' onClick={() => { dispatch(setSelectedSetting(settingMenu)); dispatch(setSelectedApp("SETTING")) }} />
                        </>
                    );
                case "SummaryDoc":
                return (
                    <>
                        <span className='d-inline-block'>{ t("common.summaryDoc") }</span>
                        <AiOutlineSetting size={ 20 } className='ml-2 cursor-pointer' onClick={() => { dispatch(setSelectedSetting(settingMenu)); dispatch(setSelectedApp("SETTING")) }} />
                    </>
                );
                case "CHAT":
                    return (
                        <>
                            <span className='d-inline-block'>{ t("common.chat") }</span>
                            <AiOutlineSetting size={ 20 } className='ml-2 cursor-pointer' onClick={() => { dispatch(setSelectedSetting(settingMenu)); dispatch(setSelectedApp("SETTING")) }} />
                        </>
                    );
                case "SETTING":
                    // return <>설정</>;
                    return (
                        <>
                            <SelectedTextLink />
                            <span className='d-inline-block'>{ t("common.setup")}</span>
                        </>
                    )
                
                default:
                    return <></>;
            }
        }

        
    }

    function SelectedTextLink() {

        let text;
        switch(selectedSetting) {
            case "CHAT":
                text = t("common.chat")
                break;
            case "TranslateText":
                text = t("common.translateText")
                break;
            case "TranslateDoc":
                text = t("common.translateDoc")
                break;
            case "SummaryDoc":
                text = t("common.summaryDoc")
                break;
            case "InterviewPrep":
                text = t("common.interviewPrep")
                break;
            case "WritingTutor":
                text = t("common.writingTutor")
                break;
            case "SpeakingTutor":
                text = t("common.speakingTutor")
                break;
            default:
                text = "";
                break;
        }

        return (
            <>
                <span className='d-inline-block cursor-pointer' onClick={() => dispatch(setSelectedApp(selectedSetting))}>
                    { text }
                </span>
                {
                    text != "" && (
                        <AiOutlineRight className='ml-2 mr-2' />
                    )
                }
            </>
        )
    }

    useEffect(() => {
        // setTimeout(function(){
            var dialogParent = $(".csmsai-modal").parent();
            console.log("dialogParent : ", dialogParent);
            dialogParent.css("z-index","1060");
        // }, 100);

    },[uiModalAiBox, stateModalAiBox]);

    const closeModalAi = () => {

        let modalState = "";

        if(uiSmallAiBox) {
            modalState = 'small';
        } else {
            modalState = 'modal';
        }
        
        const cookies = new Cookies();

        cookies.set('aichat-state', modalState, {
            path: '/',
        });

        dispatch( setUiModalAiBox(false) );
        dispatch( setUiSmallAiBox(false) );

        dispatch(setUiShowAiBox(false));
        dispatch(setSelectedApp(false)) // init modal
    }

    function openSpaceList(){

        dispatch( setUiStateBlock(true) )
        dispatch( setUiStateLeftSidebar(true) );
    }

    function closeSpaceList(){
        dispatch( setUiStateBlock(false) )
        dispatch( setUiStateLeftSidebar(false) )
    }

    function linkToPromptGuide() {
        window.open('https://support.coursemos.kr/ko/faq/ai/list/70200', '_blank')
    }

    function RenderSmallBlock() {
        const stateUiBlock = useSelector(state => state.ui.uiStateBlock);

        return (
            <div id={ stateUiBlock ? 'uiBlock' : '' } onClick={() => closeSpaceList()}></div>
        )
    }

    return (
        <>
            { userToken != false && <BtnCsmsAi/> }

            {
                (uiShowAiBox && uiSmallAiBox) && (
                    <div 
                        className={(selectedApp == "MAIN" || selectedApp == false) ? "aiBox-small small-background-color" : "aiBox-small" }
                        style={ getEventState() ? { } : { height: '650px' } } 
                    >
                        <RenderSmallBlock />
                            <div className={ (selectedApp == "MAIN" || selectedApp == false) ? "smallChatBox-header" : "smallChatBox-header header-color" }>
                                <div className='fl content-center'>
                                    {
                                        (selectedApp == "MAIN" || selectedApp == false) ? (
                                            <>
                                                <span className='pl-3 cursor-pointer content-center' onClick={()=>dispatch(setSelectedApp("MAIN"))}>
                                                    { t("common.ai_name") }
                                                </span>
                                                <span className='ml-2 cursor-pointer content-center' onClick={() => dispatch(setSelectedApp("SETTING")) } >
                                                    <AiOutlineSetting size={ 20 } />
                                                </span>
                                            </>
                                        ) : (
                                            <span className='content-center cursor-pointer' onClick={() => dispatch(setSelectedApp("MAIN"))}>
                                                <BiArrowBack size={ 20 } className='mr-2' />
                                                <span>{ t("common.ai_name") }</span>
                                            </span>
                                        )
                                    }
                                </div>



                                <div className='fr content-center'>
                                    <span
                                        id="btn-aitutor-toModal" 
                                        className='cursor-pointer content-center mr-2'
                                        onClick={ ()=> {
                                            dispatch(setUiSmallAiBox(false))  
                                            dispatch(setUiModalAiBox(true))
                                        }}
                                    >
                                        <HiOutlineArrowsExpand 
                                            id='icon-aitutor-toModal'
                                            size={ 16 } 
                                        />
                                    </span>
                                    <span className='cursor-pointer content-center' onClick={ closeModalAi }>
                                        <MdOutlineClose color='white' size={ 20 } />
                                    </span>
                                </div>

                                
                                <div className='cr'></div>            
                            </div>

                            {
                                (selectedApp != "MAIN" && selectedApp != false) && (
                                    <div className='menu-title-header'>
                                        <div className='fl content-center'>
                                            <CurrentIcon />
                                        </div>
                                        {
                                            selectedApp != "TranslateText" && (
                                                <div className='fr cursor-pointer icon-open-sidebar' onClick={() => { openSpaceList() }}>
                                                    <TfiMenuAlt size={ 24 } />
                                                </div>
                                            )
                                        }
                                        {
                                            selectedApp == "CHAT" && (
                                                <button className='btn-prompt-guide' onClick={ linkToPromptGuide }>{ t("aichat.prompt_guide")}</button>
                                            )
                                        }
                                        <div className='cr'></div>
                                    </div>
                                )
                            }
                        {/* <div>
                            <div className={ (selectedApp == "MAIN" || selectedApp == false) ? "smallChatBox-header" : "smallChatBox-header header-color" }>
                                <div className='fl'>
                                    <div className='content-center cursor-pointer'>
                                        {
                                            (selectedApp == "MAIN" || selectedApp == false) ? (
                                                <>
                                                    <span className='pl-3' onClick={()=>dispatch(setSelectedApp("MAIN"))}>
                                                        { t("common.ai_name") }
                                                    </span>
                                                    <span className='ml-2' onClick={() => dispatch(setSelectedApp("SETTING")) } >
                                                        <AiOutlineSetting size={ 20 } />
                                                    </span>
                                                </>
                                            ) : (
                                                <span className='content-center cursor-pointer' onClick={() => dispatch(setSelectedApp("MAIN"))}>
                                                    <BiArrowBack size={ 20 } className='mr-2' />
                                                    <span>{ t("common.ai_name") }</span>
                                                </span>
                                            )
                                        }
                                    </div>
                                </div>
                            
                                <div className='fr cursor-pointer d-flex'
                                    onClick={ closeModalAi }
                                >
                                    <IoIosClose className='font-lg cursor-pointer' />
                                </div>

                                <div
                                    id="btn-aitutor-toModal" 
                                    className='fr cursor-pointer mt-1 d-flex'
                                    onClick={ ()=> {
                                            dispatch(setUiSmallAiBox(false))  
                                            dispatch(setUiModalAiBox(true))
                                        }}
                                >
                                    <HiOutlineArrowsExpand 
                                        id='icon-aitutor-toModal'
                                        size={ 16 } 
                                        className='mr-1 cursor-pointer'
                                    />
                                </div>
                                <div className='cr'></div>            
                            </div>

                            {
                                (selectedApp != "MAIN" && selectedApp != false) && (
                                    <div className='menu-title-header'>
                                        <div className='fl content-center'>
                                            <CurrentIcon />
                                        </div>
                                        {
                                            selectedApp != "TranslateText" && (
                                                <div className='fr cursor-pointer icon-open-sidebar' onClick={() => { openSpaceList() }}>
                                                    <TfiMenuAlt size={ 24 } />
                                                </div>
                                            )
                                        }
                                        {
                                            selectedApp == "CHAT" && (
                                                <button className='btn-prompt-guide' onClick={ linkToPromptGuide }>{ t("aichat.prompt_guide")}</button>
                                            )
                                        }
                                        <div className='cr'></div>
                                    </div>
                                )
                            }
                        </div> */}
                        <CurrentApp />
                        <div className="box_version">
                            V.{APP_VERSION}
                        </div>
                    </div>
                )
            }

            {
                (uiShowAiBox && uiModalAiBox) && (
                    <Modal
                        animation={ false }
                        show={ uiModalAiBox }
                        onHide={() => setStateModalAiBox(false)}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        backdropClassName="csmsai-backdrop-index"
                        dialogClassName="modal-90w csmsai-modal"
                        className="aiBox-modal"

                        enforceFocus={ false }
                        
                        contentClassName={ (selectedApp == "MAIN" || selectedApp == false) ? "modal-background-image" : "" }
                    >
                        
                        {/* <Modal.Header className='text-white header-color'> */}
                        <Modal.Header className={ (selectedApp == "MAIN" || selectedApp == false) ? "text-white" : "text-white header-color" }>


                            <div id="contained-modal-title-vcenter" className='w-100 h-100'>
                                <div className='fl content-center h-100'>
                                    <div className='cursor-pointer content-center' onClick={()=>dispatch(setSelectedApp("MAIN"))}>{ t("common.ai_name") }</div>
                                    {
                                        (selectedApp == false || selectedApp == "MAIN") && (
                                            <div className='cursor-pointer ml-2 content-center' onClick={() => dispatch( setSelectedApp("SETTING") )}>
                                                <AiOutlineSetting size={ 20 } />
                                            </div>
                                        )
                                    }

                                    { selectedApp != "MAIN" && selectedApp != false && <AiOutlineRight className='ml-2 mr-2' /> }
                                    <div className='content-center'>
                                        <CurrentIcon />
                                    </div>
                                </div>

                                <div className='fr content-center h-100'>
                                    {
                                        (selectedApp == "CHAT") && (
                                            <button className='btn-prompt-guide' onClick={ linkToPromptGuide }>{ t("aichat.prompt_guide")}</button>
                                        )
                                    }
                                    <div
                                        id="btn-aitutor-toSmall"
                                        className='btn-small cursor-pointer'
                                        onClick={()=>{
                                            dispatch(setUiSmallAiBox(true)) 
                                            dispatch(setUiModalAiBox(false)) 
                                        }}
                                    >
                                        <TbWindowMinimize
                                            id='icon-aitutor-toSmall'
                                        />
                                    </div>
                                    
                                    <div className='btn-close' onClick={()=> closeModalAi() }>
                                        <IoIosClose className='font-lg cursor-pointer'/>
                                    </div>
                                </div>
                                <div className='cr'></div>
                            </div>


                            {/* <div id="contained-modal-title-vcenter" style={{ fontSize: '1.25em', float: 'left', width: '100%' }}>
                                <div className='fl content-center header-current-text'>
                                    <div className='cursor-pointer content-center' onClick={()=>dispatch(setSelectedApp("MAIN"))}>{ t("common.ai_name") }</div>
                                    {
                                        (selectedApp == false || selectedApp == "MAIN") && (
                                            <div className='cursor-pointer ml-2 content-center' onClick={() => dispatch( setSelectedApp("SETTING") )}>
                                                <AiOutlineSetting size={ 20 } />
                                            </div>
                                        )
                                    }

                                    { selectedApp != "MAIN" && selectedApp != false && <AiOutlineRight className='ml-2 mr-2' /> }
                                    <div className='content-center'>
                                        <CurrentIcon />
                                    </div>
                                    
                                </div>

                                <div className='fr content-center header-current-text'>
                                    {
                                        (selectedApp == "CHAT") && (
                                            <button className='btn-prompt-guide' onClick={ linkToPromptGuide }>{ t("aichat.prompt_guide")}</button>
                                        )
                                    }
                                    <div
                                        id="btn-aitutor-toSmall"
                                        className='btn-small cursor-pointer fl'
                                        onClick={()=>{
                                            dispatch(setUiSmallAiBox(true)) 
                                            dispatch(setUiModalAiBox(false)) 
                                        }}
                                    >
                                        <TbWindowMinimize
                                            id='icon-aitutor-toSmall'
                                        />
                                    </div>
                                    
                                    <div
                                        className='btn-close fr'
                                        onClick={()=> closeModalAi() }
                                    >
                                        <IoIosClose className='font-lg cursor-pointer'/>
                                    </div>
                                </div>

                                <div className='cr'></div>
                            </div> */}
                        </Modal.Header>
                        <Modal.Body>
                            <CurrentApp />
                            <div className="box_version">
                                V.{APP_VERSION}
                            </div>
                        </Modal.Body>
                    </Modal>
                )
            }
        </>
    );
}

export default Common; 