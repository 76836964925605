import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { AiOutlinePlusCircle } from 'react-icons/ai'
import { FiSidebar } from 'react-icons/fi'
import { BiTrash } from 'react-icons/bi'
import { FoldingCube } from 'better-react-spinkit'
import axios from "axios";
import { immediateToast } from "izitoast-react";

import "izitoast-react/dist/iziToast.css";
import { setAiChatSelectedSpace, setAiChatSelectedSpaceChatHistory, setAiChatSpaceList, setNewChatsSpace } from "../../../modules/aiChat";
import { setLoadingSpace, setLoadingAnswering, setLoadingWork } from "../../../modules/loading";

import { setUiHideChatSidebar } from "../../../modules/ui";
import { useTranslation } from "react-i18next"; "../../../langs/i18n";
import { Trans } from "react-i18next";
import { RootState } from "../../../modules";

declare const API_URL: string;
declare const API_VERSION: string;

const ChatSidebar = () => {

    type AiChatSelectedType = {
        appType: string,
        created_at: string,
        label: string,
        orgUser_id: string,
        space_id: string,
        updated_at: string
    }

    const dispatch = useDispatch();

    const { t, i18n } = useTranslation();

    const [sidebarIcon, setSidebarIcon] = useState(false)

    const uiSmallAiBox = useSelector((state: RootState) => state.ui.uiSmallAiBox);
    const uiHideChatSidebar = useSelector((state: RootState) => state.ui.uiHideChatSidebar);
    const stateLeftSidebar = useSelector((state: RootState) => state.ui.uiStateLeftSidebar);
    const loadingSpace = useSelector((state: RootState) => state.loading.loadingSpace);
    const loadingSpaceList = useSelector((state: RootState) => state.loading.loadingSpaceList);
    const orgCode = useSelector((state: RootState) => state.organization.orgCode);
    const aiChatSpaceList = useSelector((state: RootState) => state.aiChat.aiChatSpaceList);
    const aiChatSelectedSpace = useSelector((state: RootState) => state.aiChat.aiChatSelectedSpace);
    const aiNewChatSpace = useSelector((state: RootState) => state.aiChat.aiNewChatSpace);
    const userToken = useSelector((state: RootState) => state.user.userToken);
    const fetchController = useSelector((state: RootState) => state.apps.fetchController);

    useEffect(() => {

        if(aiNewChatSpace) {
            // setNewSpace(false); 
            dispatch( setNewChatsSpace(false) ); // 이제 공간이 존재하기에 false로 변경
        } else {
            if(aiChatSelectedSpace != false){
                console.log("aiChatSelectedSpace : ", aiChatSelectedSpace);
            }else{
                dispatch( setAiChatSelectedSpaceChatHistory([]) )
            }
        }
        
    }, [aiChatSelectedSpace])

    function getChatSpace(item: any){
        
        if(!loadingSpace) {
            if(fetchController != false){
                fetchController.abort();  
            }
            dispatch( setAiChatSelectedSpace({ space_id: item.space_id }) );
            dispatch(setLoadingSpace(true));
    
            setTimeout(() => {
    
                var data = JSON.stringify({
                    "orgCode" : orgCode,
                    "uToken" : userToken,
                    "type" : "aiChat",
                    "spaceId" : item.space_id
                });
                var config = {
                  method: 'post',
                  url:  API_URL + '/' + API_VERSION + '/space/getSpace',
                  headers: { 
                    'Content-Type': 'application/json'
                  },
                  data : data
                };
          
                axios(config)
                .then( (response) => {
          
                    console.log(response.data)
                    if( response.data.code == 100){
                      console.log("SUCCESS")
                      var spaceData = response.data.spaceData;
                      var spaceInfo = response.data.spaceInfo;
                      spaceData = [...spaceData]
                      
                        
                        dispatch(setAiChatSelectedSpaceChatHistory(spaceData));
                        dispatch(setAiChatSelectedSpace(spaceInfo))
                        dispatch(setLoadingSpace(false));
                        if(spaceInfo.answering != false){
                            dispatch(setLoadingWork(true))
                            dispatch(setLoadingAnswering(true))
                        }else{
                            dispatch(setLoadingWork(false))
                            dispatch(setLoadingAnswering(false))
                        }

                    }
                })
                .catch((error) => {
                    console.log(error);
                    dispatch(setLoadingSpace(false));
                });
    
            }, 500); // 채팅방 클릭 / 답변 완료 후 타입아웃 추가
        }
        
    }

    function delChatSpace(item: AiChatSelectedType) {
        
        immediateToast("info", {
            title: t("aichat.delete_title"),
            message: t("aichat.delete_content"),
            position: 'topCenter',
            timeout: 2000,
            zindex: 999999999
        });

        var data = JSON.stringify({
            "orgCode" : orgCode,
            "uToken" : userToken,
            "type" : "aiChat",
            "spaceId" : item.space_id
        });

        var config = {
            method: 'post',
            url:  API_URL + '/' + API_VERSION + '/space/delSpace',
            headers: { 
                'Content-Type': 'application/json'
            },
            data : data
        };

        axios(config)
        .then( (response) => {
            console.log(response.data)

            if( response.data.code == 100){
                console.log("SUCCESS")

                immediateToast("success", {
                    title: t("aichat.delete_title"),
                    message: t("aichat.delete_success"),
                    position: 'topCenter',
                    timeout: 2000,
                    zindex: 999999999
                });

                // 채팅 사이드바 갱신
                const updateList = aiChatSpaceList.filter((list: AiChatSelectedType) => list.space_id != item.space_id)
                dispatch( setAiChatSpaceList(updateList) );

                if(aiChatSelectedSpace.space_id == item.space_id) {
                    // 지금 선택한 채팅 페이지 삭제
                    dispatch( setAiChatSelectedSpace(false) )
                }
            }
        })
        .catch((error) => {
            console.log(error);
        });
    } // Delete Chatting Space

    return (
        <>
            {
                uiSmallAiBox ? (
                    <div className="leftSidebar-list fl" style={ stateLeftSidebar ? { height: '445px' } : { height: '0px' } }>
                        <div className="sidebar-list-title">
                            <div className="fl">
                                { t("aichat.chat_list") }
                            </div>
                            <div className="count-wrapper fr">
                                <Trans
                                    i18nKey={ "aichat.chat_count" }
                                    values={{
                                        count: aiChatSpaceList.length
                                    }}
                                    components={{
                                        text: <></>,
                                        color: <span className="count-color"></span>
                                    }}
                                />
                            </div>
                            <div className="cr"></div>
                        </div>
                        {
                            loadingSpaceList &&
                                <div className='justify-content-center d-flex align-items-center h-100'>
                                    <FoldingCube size={20} color="#C0C0C0" />
                                </div>
                        }
                        <ul className="mb-0 sidebar-list-wrapper">
                            {
                                aiChatSpaceList.length > 0 &&
                                Object.keys(aiChatSpaceList).map(v => 
                                    <li key={aiChatSpaceList[v].space_id}
                                        className={ aiChatSelectedSpace != "false" ? (
                                            aiChatSelectedSpace.space_id == aiChatSpaceList[v].space_id ? "btn-select-space pl-2 selected-space-room" : "btn-select-space pl-2"
                                            ) : ""
                                        }
                                        onClick={() => getChatSpace(aiChatSpaceList[v])}
                                        onMouseEnter={() => setSidebarIcon( aiChatSpaceList[v].space_id ) }
                                        onMouseLeave={() => setSidebarIcon(false) }
                                    >
                                        <div className="space-label fl text-truncate">
                                            {aiChatSpaceList[v].label}
                                        </div>
                                        {
                                            sidebarIcon != false && (
                                                sidebarIcon == aiChatSpaceList[v].space_id && (
                                                    <div
                                                        className="fr pr-2"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            delChatSpace( aiChatSpaceList[v] );
                                                        }}
                                                    >
                                                        <BiTrash size={ 14 } />
                                                    </div>
                                                )
                                            )
                                        }
                                        <div className="cr" />
                                    </li>
                                )
                            }
                        </ul>

                        <div className='leftSidebar-optionList'>
                            <div className="add-new-space-box" onClick={()=> { dispatch(setAiChatSelectedSpace(false)); }}>
                                <AiOutlinePlusCircle className="mr-2" size={ 14 } />
                                { t("aichat.new_chat") }
                            </div>
                        </div>
                    </div>
                ) : (
                        <>
                            <div className='leftSidebar-list fl' style={ ( !uiSmallAiBox && uiHideChatSidebar ) ? { marginLeft: '-460px' } : {} } >
                                {
                                    !uiSmallAiBox && (
                                        <div className='add-btn-wrapper d-flex'>
                                            <div className='btn-add' onClick={()=>  { dispatch( setAiChatSelectedSpace(false) ); } }>
                                                <div className="content-center">
                                                    <AiOutlinePlusCircle className='mt-n1 mr-2' />
                                                    <div className="btn-add-text">{ t("aichat.new_chat") }</div>
                                                </div>
                                            </div>
                                            <div className="btn-close"  onClick={() => dispatch(setUiHideChatSidebar(true)) }>
                                                <FiSidebar />
                                            </div>
                                        </div>
                                    )
                                }
                                <div className="sidebar-list-title">
                                    { t("aichat.chat_list") }
                                </div>
                                <ul className="mb-0 sidebar-list-wrapper">
                                    {
                                        loadingSpaceList ? 
                                            <div className='content-center h-100'>
                                                <FoldingCube size={20} color="#C0C0C0" />
                                            </div>
                                        :
                                            <>
                                                {
                                                    aiChatSpaceList.length > 0 &&
                                                    Object.keys(aiChatSpaceList).map(v => 
                                                        <li key={aiChatSpaceList[v].space_id}
                                                            className={ aiChatSelectedSpace.space_id == aiChatSpaceList[v].space_id ? 
                                                                "btn-select-space selected-space-room pl-2 pr-3 pt-3 pb-3 cursor-pointer d-flex" : 
                                                                "btn-select-space pl-2 pr-3 pt-3 pb-3 cursor-pointer d-flex" }
                                                            /*
                                                            onClick={()=>{
                                                                if(!loadingSpace) {
                                                                    getChatSpace(aiChatSpaceList[v])
                                                                }
                                                            }}
                                                            */
                                                            onClick={(e) => {
                                                                getChatSpace(aiChatSpaceList[v])
                                                            }}

                                                            onMouseEnter={() => setSidebarIcon( aiChatSpaceList[v].space_id ) }
                                                            onMouseLeave={() => setSidebarIcon(false) }
                                                        >
                                                            <div className="space-label fl text-truncate">
                                                                {aiChatSpaceList[v].label}
                                                            </div>
                                                            {
                                                                sidebarIcon != false && (
                                                                    sidebarIcon == aiChatSpaceList[v].space_id && (
                                                                        <div
                                                                            className="fr"
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                delChatSpace( aiChatSpaceList[v] );
                                                                            }}    
                                                                        >
                                                                            <BiTrash size={ 14 } />
                                                                        </div>
                                                                    )
                                                                )
                                                            }
                                                            {/* <div className="fr">
                                                                <BiTrash size={ 15 } />
                                                            </div> */}
                                                            <div className="cr" />
                                                        </li>
                                                    )
                                                }
                                            </>
                                    }
                                </ul>
                            </div>
                            <div 
                                className="btn-display-sidebar"
                                style={ uiHideChatSidebar ? { opacity: 1, transition: 'opacity 0.5s', visibility: 'visible' } : { opacity: 0, transition: 'opacity 0.5s', visibility: 'hidden' } }
                                onClick={() => { dispatch(setUiHideChatSidebar(false) ); }}
                            >
                                <FiSidebar />
                            </div>
                        </>
                )
            }
        </>
    )
}

export default ChatSidebar;