import React, { useState, useEffect, useRef  } from 'react';
import { useSelector, useDispatch } from "react-redux"

import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import remarkParse from 'remark-parse'
import remarkBreaks from 'remark-breaks'
import remarkMath from 'remark-math'
import rehypeKatex from 'rehype-katex'

import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark as syntaxStyle } from 'react-syntax-highlighter/dist/esm/styles/prism';
import copy from 'copy-to-clipboard';


import { ThreeBounce, FoldingCube } from 'better-react-spinkit'
import { AiOutlineRobot } from 'react-icons/ai'
import { HiUserCircle } from 'react-icons/hi'
import { BsFillArrowDownCircleFill } from 'react-icons/bs'
import { TbClipboardCopy } from 'react-icons/tb'
import { HiArrowPath, HiStop } from 'react-icons/hi2';



import { setAiChatSelectedSpaceChatHistory } from "../../../modules/aiChat";
import { setLoadingAnswering, setLoadingWork } from "../../../modules/loading";
import { setFetchController } from "../../../modules/apps";


import 'katex/dist/katex.min.css' // `rehype-katex` does not import the CSS for you
import { Button } from "react-bootstrap"
import { useTranslation } from "react-i18next";

const ChatSpace = () => {
    const dispatch = useDispatch();
    const chatSize = 400;
    
    const chatSpaceBottomRef = useRef(null);
    const chatSpaceRef = useRef(null);

    const uiSmallAiBox = useSelector(state => state.ui.uiSmallAiBox);
    const aiChatSelectedSpaceChatHistory = useSelector(state => state.aiChat.aiChatSelectedSpaceChatHistory);
    
    const aiChatSelectedSpace = useSelector(state => state.aiChat.aiChatSelectedSpace);

    const loadingState = useSelector(state => state.loading);

    const uiHideChatSidebar = useSelector(state => state.ui.uiHideChatSidebar);
    const loadingAnswering = useSelector(state => state.loading.loadingAnswering);
    

    const userToken = useSelector(state => state.user.userToken);
    const orgCode = useSelector(state => state.organization.orgCode);

    const { t, i18n } = useTranslation();

    
    useEffect(() => {/*
        if(chatSpaceBottomRef.current != null) {
            chatSpaceBottomRef.current.scrollIntoView({ behavior: "instant" });
        }
        */
       console.log("aiChatSelectedSpace : ", aiChatSelectedSpace);
    }, [aiChatSelectedSpace])
    

    useEffect(() => {
        if(chatSpaceBottomRef.current != null) {
            chatSpaceBottomRef.current.scrollIntoView({ behavior: "instant" });
        }
    }, [loadingState.loadingSpace])

    useEffect(() => {
        if(chatSpaceRef.current != null) {
            const top = chatSpaceRef.current.scrollTop; // 채팅창 스크롤 상단 높이
            const height = chatSpaceRef.current.scrollHeight; // 채팅창 전체 높이
            const clientHeight = chatSpaceRef.current.clientHeight; // 클라이언트 페이지 높이

            if(height - top < clientHeight + chatSize) {
                if(chatSpaceBottomRef.current != null) {
                    chatSpaceBottomRef.current.scrollIntoView({ behavior: "smooth" })
                }
            }
        }
    }, [chatSpaceRef.current?.scrollHeight])

    useEffect(() => {
        if(loadingState.loadingWork) {
            if(chatSpaceRef.current != null) {
                const top = chatSpaceRef.current.scrollTop; // 채팅창 스크롤 상단 높이
                const height = chatSpaceRef.current.scrollHeight; // 채팅창 전체 높이
                const clientHeight = chatSpaceRef.current.clientHeight; // 클라이언트 페이지 높이
    
                if(height - top < clientHeight + chatSize) {
                    if(chatSpaceBottomRef.current != null) {
                        chatSpaceBottomRef.current.scrollIntoView({ behavior: "smooth" })
                    }
                }
            }
        }
        
    }, [loadingState.loadingWork])

    function reWorkLastChat(){

        var controller = new AbortController();
        dispatch( setFetchController(controller) );
        const signal = controller.signal;

        dispatch(setLoadingAnswering(true))
        dispatch(setLoadingWork(true))

        var lastMessage = aiChatSelectedSpaceChatHistory[aiChatSelectedSpaceChatHistory.length-1];
        console.log("getLastMessage : ", lastMessage)
        var data = {
            "orgCode" : orgCode,
            "uToken" : userToken,
            "message" : lastMessage.message, // 채팅 앞, 뒤 공백 제거
            "type" : "aiChat",
            "userMsgId" : lastMessage.msgId,
            "space_id" : aiChatSelectedSpace.space_id
        };      
        var requestData = JSON.stringify(data);
        console.log("data : ", JSON.parse(requestData))
        var tmpHistory = aiChatSelectedSpaceChatHistory;
        fetch("https://aichat.coursemos.kr/v2/aiChat", {
            signal,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
              },
              body: requestData,
        })
        .then(response => {
            console.log(response.headers.get('content-type'))

            if (!response.ok) {
                console.log("requestError");
            }

            if (response.status === 200 && response.headers.get('content-type') === 'text/event-stream') {

              const reader = response.body.getReader();
              const decodeText = buffer => {
                const decoder = new TextDecoder();
                return decoder.decode(buffer, { stream: true });
              };
              
              const readChunk = () => {
                return reader.read()
                  .then(({ done, value }) => {
                    if (done) {
                      console.log('Streaming completed');
                      dispatch(setLoadingWork(false)); // 채팅을 보냈을 때, 로딩값 true로 설정
                      return;
                    }
  
                    const chunk = decodeText(value);
                    console.log( chunk ) ;
                    // "}{"" 패턴을 기준으로 JSON 객체들을 분리
                    const jsonObjects = chunk.split('}{');

                    // 각 JSON 객체 파싱 후 출력
                    jsonObjects.forEach((jsonObject, index) => { 
                    // 맨 앞과 맨 뒤에 중괄호가 없는 경우, 중괄호를 추가하여 유효한 JSON 형식으로 만듦
                    if (index !== 0) {
                        jsonObject = '{' + jsonObject;
                    }
                    if (index !== jsonObjects.length - 1) {
                        jsonObject = jsonObject + '}';
                    }
                    
                    try {
                        const parsedObject = JSON.parse(jsonObject);
                        //console.log(parsedObject);
                        switch(parsedObject.state){

                            
                            case "streaming":
                                console.log(parsedObject.content)
                                const entry = Object.entries(tmpHistory).find(([key, value]) => value.msgId === parsedObject['assistantMsgId']);
                                if (entry) {
                                    const [key, value] = entry;
                                    value.message += parsedObject['content'];
                                    tmpHistory = [...tmpHistory]
                                    
                                    dispatch(setAiChatSelectedSpaceChatHistory(tmpHistory))
                                    //console.log("content가 성공적으로 수정되었습니다. : ", value);
                                } else {
                                    //console.log("해당 msgId를 가진 데이터를 찾을 수 없습니다.");
                                    tmpHistory.push({
                                        "message" : parsedObject['content'],
                                        "from" : parsedObject['role'],
                                        "msgId" : parsedObject['assistantMsgId']
                                    })
                                    
                                    tmpHistory = [...tmpHistory]
                                    
                                    dispatch(setAiChatSelectedSpaceChatHistory(tmpHistory))  
                                }

                            break;
                            case "end":
                                console.log("END DATA : ", parsedObject) 
                                dispatch(setLoadingAnswering(false))                                
                            break;


                        }
                        
                        
                    } catch (error) {
                        console.error("JSON 파싱 에러:", error);
                    }
                    });

                    //var chunkData = JSON.parse(chunk);
                    //console.log(chunkData.content);
                    // 원하는 처리를 수행합니다.
                    return readChunk(); // 다음 chunk를 읽습니다.
                  });
              };
  
              return readChunk(); // 첫 번째 chunk를 읽습니다.
            } else {
                dispatch(setLoadingWork(false)); // 채팅을 보냈을 때, 로딩값 true로 설정
              console.error('Invalid response:', response.status);
            }
          })
        .catch(error => {
            console.error('Fetch error:', error);
            dispatch(setLoadingWork(false)); // 채팅을 보냈을 때, 로딩값 true로 설정
            /*
            controller.reason();
            setTimeout(function(){
                
            }, 2000)
            */
          
        });
        
    }

    function scrollToBottom() {
        if(chatSpaceBottomRef.current != null) {
            chatSpaceBottomRef.current.scrollIntoView({ behavior: "smooth" }); // head to last div    
        }
    }

    function RenderTable(props) {
        return <table className="table table-striped">{props.children}</table>
      }
    function parseHTML(html) {
        var t = document.createElement('template');
        t.innerHTML = html;
        return t.content;
    }

    return (
        <>
            {
                loadingState.loadingSpace ?

                <div className={ uiSmallAiBox ? "small-loading" : "modal-loading" }>
                    {
                        uiSmallAiBox ?
                        <FoldingCube size={30} color="#C0C0C0" />
                        :
                        <FoldingCube size={30} color="#C0C0C0" />
                    }
                </div>
                :
                <div className={uiHideChatSidebar ? '' : 'sidebar-hided' } style={{ height: 'calc(100% - 40px', overflowY: 'auto' }} ref={ chatSpaceRef }>
                    {
                        //이미 처리 된 것들 렌더링
                        aiChatSelectedSpaceChatHistory.length > 0 &&
                            Object.keys(aiChatSelectedSpaceChatHistory).map(v => {
                                
                                if( aiChatSelectedSpaceChatHistory[v]['from'] == "user" ) {
                                    
                                    if( typeof aiChatSelectedSpaceChatHistory[ parseInt(v) + 1 ] != 'undefined' ) {
                                        
                                        if( aiChatSelectedSpaceChatHistory[ parseInt(v) + 1 ]['from'] == "assistant" ) {
                                            
                                            // 질문에 대한 답변이 존재할 경우
                                            return (
                                                <div key={ 'message-' + v } className='chat-msg-box'>
                                                    <div className='user-msg-box'>
                                                        <div className='user-icon'>
                                                            <HiUserCircle size={ 30 } color='#E0E0E0' />
                                                        </div>
        
                                                        <div className='user-msg-content'>
                                                            { aiChatSelectedSpaceChatHistory[v]['message'] }
                                                        </div>
        
                                                        <div className="cr"></div>
                                                    </div>
                                                    <div className='assistant-msg-box'>
        
                                                        <div className='assistant-icon-box'>
                                                            <div className='assistant-icon'></div>
                                                        </div>
        
                                                        <div className='assistant-msg-content'>
                                                            <ReactMarkdown children={aiChatSelectedSpaceChatHistory[ parseInt(v) + 1 ]['message']} 
                                                                            remarkPlugins={[remarkGfm, remarkParse, remarkBreaks, remarkMath]}
                                                                            rehypePlugins={[rehypeKatex]}
                                                                            linkTarget="_blank"
                                                                            components={{
                                                                                code({node, inline, className, children, ...props}) {
                                                                                const match = /language-(\w+)/.exec(className || '')
                                                                                return !inline && match ? (
                                                                                    <>
                                                                                        <div className='header-syntaxHighlighter'>
                                                                                            <div className='fl label-syntaxHighlighter'>
                                                                                                {match[1]}
                                                                                            </div>
                                                                                            <div className='fr copy-syntaxHighlighter' >
                                                                                                <div 
                                                                                                    
                                                                                                    onClick={(e)=>{
                                                                                                        var transHtml = '<svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h3m9 -9v-5a2 2 0 0 0 -2 -2h-2"></path><path d="M13 17v-1a1 1 0 0 1 1 -1h1m3 0h1a1 1 0 0 1 1 1v1m0 3v1a1 1 0 0 1 -1 1h-1m-3 0h-1a1 1 0 0 1 -1 -1v-1"></path><path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z"></path></svg> Copy';
                                                                                                        console.log(e.target.innerHTML)
                                                                                                        if(e.target.innerHTML.includes("Copy")){
                                                                                                            copy(String(children).replace(/\n$/, ''));
                                                                                                            e.target.innerHTML="Copied!"
                                                                                                            setTimeout(function(){
                                                                                                                    e.target.innerHTML=transHtml
                                                                                                            },2000);
                                                                                                        }
                                                                                        
                                                                                                            
                                                                                                    }}        
                                                                                                    
                                                                                                >
                                                                                                    <div><TbClipboardCopy/> Copy</div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className='cr'></div>
                                                                                        </div>                                                    
                                                                                        <SyntaxHighlighter
                                                                                            {...props}
                                                                                            children={String(children).replace(/\n$/, '')}
                                                                                            style={syntaxStyle}
                                                                                            language={match[1]}
                                                                                            showLineNumbers={true}
                                                                                            PreTag="div"
                                                                                            customStyle={{
                                                                                                marginTop : '0px',
                                                                                                borderRadius: '0 0 0.3em 0.3em'
                                                                                            }}
                                                                                        />
                                                                                        
                                                                                    </>
                                                                                ) : (
                                                                                    <code {...props} className={className}>
                                                                                    {children}
                                                                                    </code>
                                                                                )
                                                                                },
                                                                                table: ({node, ...props}) => <table className='table table-hover table-striped table-bordered table-dark' {...props} />
                                                                            }}
                                                                            renderers={{ Table: RenderTable}} 
                                                            
                                                            ></ReactMarkdown>
                                                        </div>
        
                                                        <div className="cr"></div>
                                                        
                                                    </div>
                                                    
                                                </div>
        
                                            )
                                        } else {

                                            // 질문에 대한 답변이 존재하지 않을 경우
                                            return (
                                                <div key={ 'message-' + v } className='chat-msg-box'>
                                                    <div className='user-msg-box'>
                                                        <div className='user-icon'>
                                                            <HiUserCircle size={ 30 } color='#E0E0E0' />
                                                        </div>

                                                        <div className='user-msg-content'>
                                                            { aiChatSelectedSpaceChatHistory[v]['message'] }
                                                        </div>

                                                        <div className="cr"></div>
                                                    </div>

                                                </div>
                                            )
                                        }
                                    } else {
                                        return (
                                            <div key={ 'message-' + v } className='chat-msg-box'>
                                                <div className='user-msg-box'>
                                                    <div className='user-icon'>
                                                        <HiUserCircle size={ 30 } color='#E0E0E0' />
                                                    </div>

                                                    <div className='user-msg-content'>
                                                        { aiChatSelectedSpaceChatHistory[v]['message'] }
                                                    </div>

                                                    <div className="cr"></div>
                                                </div>
                                                <div className='assistant-msg-box'>
                                                    
                                                    <div className='assistant-icon-box'>
                                                        <div className='assistant-icon'></div>
                                                    </div>

                                                    <div className='assistant-msg-content'>
                                                        {
                                                            loadingAnswering == true?
                                                                <ThreeBounce color={ "#808080" }/>
                                                            :
                                                                <>
                                                                    <div className="fl">*{ t("aichat.error") }</div>
                                                                    <div className="btn btn-info btn-xs fr mr-8" onClick={reWorkLastChat} ><HiArrowPath style={{verticalAlign: 'text-top'}}/> { t("aichat.retry") }</div>
                                                                    <div className="cr"></div>
                                                                </>
                                                                
                                                        }
                                                        

                                                    </div>

                                                    <div className="cr"></div>
                                                    
                                                </div>

                                            </div>
                                        )     
                                    }
                                }
                                // assistant인 경우 생략

                            }
                        )
                    }
                    <div className='chat-space-bottom-ref' ref={ chatSpaceBottomRef } />
                </div>
            }
            { !loadingState.loadingSpace && <><BsFillArrowDownCircleFill className='btn-bottom-scroll' onClick={ scrollToBottom } /></> }
            
        </>
    )
}

export default ChatSpace