import React from "react";
import { useSelector } from "react-redux";

const MainTopBanner = () => {

    const uiShowAiBox = useSelector(state => state.ui.uiShowAiBox);
    const uiSmallAiBox = useSelector(state => state.ui.uiSmallAiBox);
    const orgCode = useSelector(state => state.organization.orgCode);

    const RenderSmallView = () => {
        return (
            <>
                {
                    orgCode == "hallym_dev" && (
                        <div className='top-banner-text'>
                            <div className='top-banner-title'>ⓘ AI 튜터 사용안내</div>
                            <div>AI 튜터는 한시적으로 운영합니다. AI 튜터 기능을 효율적인 학습활동에 많은 활용 바랍니다.</div>
                            <div>[사용기간 : 2024.06.01 ~ 2025.02.28]</div>
                        </div>
                    )
                }
            </>
        )
    }

    const RenderModalView = () => {
        return (
            <>
                {
                    orgCode == "hallym_dev" && (
                        <div className='top-banner-text'>
                            <div className='top-banner-title'>ⓘ AI 튜터 사용안내</div>
                            <div>AI 튜터는 한시적으로 운영합니다. AI 튜터 기능을 효율적인 학습활동에 많은 활용 바랍니다.</div>
                            <div>[사용기간 : 2024.06.01 ~ 2025.02.28]</div>
                        </div>
                    )
                }
            </>
        )
    }

    return (
        <>
            {
                uiShowAiBox && (
                    uiSmallAiBox ? (
                        <RenderSmallView />
                    ) : (
                        <RenderModalView />
                    )
                )
            }
        </>
    )
}

export default MainTopBanner