import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { InputGroup, FormControl, Button } from "react-bootstrap";
import { IoIosSend } from 'react-icons/io'
import { ThreeBounce } from 'better-react-spinkit'
import { setAiChatMessage, setAiChatSelectedSpace, setAiChatSelectedSpaceChatHistory, setAiChatSpaceList } from "../../../modules/aiChat";
import { setLoadingAnswering } from "../../../modules/loading";
import { setFetchController } from "../../../modules/apps";


import { setLoadingSpace, setLoadingWork } from "../../../modules/loading";
import { setSelectedPrompt } from "../../../modules/aiChat";
import { IoIosClose } from 'react-icons/io';
import { HiArrowPath, HiStop } from 'react-icons/hi2';


//import { IoIosClose } from 'uuidv4';
import uuid from "uuid";
import { useTranslation } from "react-i18next";

const ChatInputGroup = () => {

    const dispatch = useDispatch();

    const focusChatInput = useRef(null)

    const [newChatFlag, setNewChatFlag] = useState(false);
    const [chatSignal, setChatSignal] = useState();
    const [controller, setController] = useState();

    const uiSmallAiBox = useSelector(state => state.ui.uiSmallAiBox);
    const userToken = useSelector(state => state.user.userToken);
    const orgCode = useSelector(state => state.organization.orgCode);
    const aiChatSelectedSpace = useSelector(state => state.aiChat.aiChatSelectedSpace);
    const loadingSpace = useSelector(state => state.loading.loadingSpace);
    const loadingWork = useSelector(state => state.loading.loadingWork);
    const selectedPrompt = useSelector(state => state.aiChat.selectedPrompt);
    const uiHideChatSidebar = useSelector(state => state.ui.uiHideChatSidebar);

    const aiChatSpaceList = useSelector(state => state.aiChat.aiChatSpaceList);
    const userMessage = useSelector(state => state.aiChat.aiChatMessage);

    const aiChatSelectedSpaceChatHistory = useSelector(state => state.aiChat.aiChatSelectedSpaceChatHistory);
      
    const fetchController = useSelector(state => state.apps.fetchController);

    const { t, i18n } = useTranslation();

    useEffect(() => {
        console.log("newChatFlag : ", newChatFlag);
        if(newChatFlag){
            var newSpaceData = aiChatSpaceList[0];
            console.log("newSpaceData : ", newSpaceData)
            dispatch(setAiChatSelectedSpace(newSpaceData));

            //getChatSpace(newSpaceData);
            setNewChatFlag(false)
        }
    }, [aiChatSpaceList])

    useEffect(() => {
        // init input focus
        if(loadingSpace == false) {
            if(focusChatInput.current != null) {
                focusChatInput.current.focus()
                console.log('focus cursor')
            }
        }
    }, [loadingSpace])

    useEffect(() => {
        if(loadingWork == false) {
            if(focusChatInput.current != null) {
                focusChatInput.current.focus()
                console.log('focus cursor')
            }
        }
    }, [loadingWork])

    function getChatSpace(item){
        console.log('start getSpace!!!')
        dispatch(setLoadingSpace(true));
        dispatch( setAiChatSelectedSpace({ space_id: item.space_id }) );

        var data = JSON.stringify({
            "orgCode" : orgCode,
            "uToken" : userToken,
            "type" : "aiChat",
            "spaceId" : item.space_id
        });
        var config = {
            method: 'post',
            url:  API_URL + '/' + API_VERSION + '/space/getSpace',
            headers: { 
            'Content-Type': 'application/json'
            },
            data : data
        };
        
    
        axios(config)
        .then( (response) => {
    
            console.log(response.data)
            if( response.data.code == 100){
                console.log("SUCCESS")
                var spaceData = response.data.spaceData;
                spaceData = [...spaceData]
                dispatch(setAiChatSelectedSpaceChatHistory(spaceData));
                dispatch( setAiChatSelectedSpace(item) )
                dispatch(setLoadingSpace(false));
            }
        })
        .catch((error) => {
            console.log(error);
            dispatch(setLoadingSpace(false));
        });
    }

    function onKeyUp(e) {
        if (e.keyCode === 13) {
            sendUserMessage();
        }
    }

    function stopAiChat(){
        console.log("STOP Generate");
        if(fetchController != false){
            fetchController.abort();  
        }
        //controller.abort();

    }

    function regenerateAiChat(){
        
    }

    function sendUserMessage(){
        var controller = new AbortController();
        dispatch( setFetchController(controller) );
        const signal = controller.signal;
        
        // 답변이 생성될때 추가적인 메세지 전송 불가
        if(!loadingWork && userMessage.trim() != "") {

            console.log(userMessage)
            dispatch(setLoadingWork(true)); // 채팅을 보냈을 때, 로딩값 true로 설정

            var data = {
                "orgCode" : orgCode,
                "uToken" : userToken,
                "message" : userMessage.trim(), // 채팅 앞, 뒤 공백 제거
                "type" : "aiChat",
                "userMsgId" : uuid.v4(),
                "space_id" : aiChatSelectedSpace.space_id
            };
            if(selectedPrompt != false){
                data['promptData'] = {
                    "uuid" : selectedPrompt.uuid,
                    "VAL_1" : userMessage.trim(),
                    //"outputLanguage" : ""
                }
            }
            var requestData = JSON.stringify(data);
            console.log("data : ", JSON.parse(requestData))

            dispatch(setAiChatMessage(""));
            dispatch(setLoadingAnswering(true))
            
            var tmpHistory = aiChatSelectedSpaceChatHistory;
            tmpHistory.push({
                "message" : data['message'],
                "from" : "user",
                "msgId" : data['userMsgId']
            })
            
            tmpHistory = [...tmpHistory]
            
            dispatch(setAiChatSelectedSpaceChatHistory(tmpHistory))           
            fetch("https://aichat.coursemos.kr/v2/aiChat", {
                    signal,
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                      },
                      body: requestData,
                })
                .then(response => {
                    console.log(response.headers.get('content-type'))

                    if (!response.ok) {
                        console.log("requestError");
                    }

                    if (response.status === 200 && response.headers.get('content-type') === 'text/event-stream') {

                      const reader = response.body.getReader();
                      const decodeText = buffer => {
                        const decoder = new TextDecoder();
                        return decoder.decode(buffer, { stream: true });
                      };
                      
                      const readChunk = () => {
                        return reader.read()
                          .then(({ done, value }) => {
                            if (done) {
                              console.log('Streaming completed');
                              dispatch(setLoadingWork(false)); // 채팅을 보냈을 때, 로딩값 true로 설정
                              return;
                            }
          
                            const chunk = decodeText(value);
                            console.log( chunk ) ;
                            // "}{"" 패턴을 기준으로 JSON 객체들을 분리
                            const jsonObjects = chunk.split('}{');

                            // 각 JSON 객체 파싱 후 출력
                            jsonObjects.forEach((jsonObject, index) => { 
                            // 맨 앞과 맨 뒤에 중괄호가 없는 경우, 중괄호를 추가하여 유효한 JSON 형식으로 만듦
                            if (index !== 0) {
                                jsonObject = '{' + jsonObject;
                            }
                            if (index !== jsonObjects.length - 1) {
                                jsonObject = jsonObject + '}';
                            }
                            
                            try {
                                const parsedObject = JSON.parse(jsonObject);
                                //console.log(parsedObject);
                                switch(parsedObject.state){
                                    case "start":
                                        console.log("START : ", parsedObject.content)
                                        console.log("START DATA : ", parsedObject)

                                        if(parsedObject.isNew){
                                            console.log("aiChatSelectedSpace : ", aiChatSelectedSpace);
                                            if(aiChatSelectedSpace == false) {
                                                setNewChatFlag(true)
                                                // dispatch(setLoadingWork(false)); // 채팅을 보냈을 때, 로딩값 true로 설정
                                                var tmpSpaceList = aiChatSpaceList;
                                                var newSpaceData = {
                                                    "appType": "aiChat",
                                                    "label": parsedObject.label,
                                                    "space_id": parsedObject.spaceId 
                                                }
                                                tmpSpaceList.unshift(newSpaceData);
                                                tmpSpaceList = [...tmpSpaceList]
                                        
                                                dispatch(setAiChatSpaceList(tmpSpaceList))
                        
                        
                                            }                                            
                                        }
                                    break;
                                    
                                    case "streaming":
                                        console.log(parsedObject.content)
                                        const entry = Object.entries(tmpHistory).find(([key, value]) => value.msgId === parsedObject['assistantMsgId']);
                                        if (entry) {
                                            const [key, value] = entry;
                                            value.message += parsedObject['content'];
                                            tmpHistory = [...tmpHistory]
                                            
                                            dispatch(setAiChatSelectedSpaceChatHistory(tmpHistory))
                                            //console.log("content가 성공적으로 수정되었습니다. : ", value);
                                        } else {
                                            //console.log("해당 msgId를 가진 데이터를 찾을 수 없습니다.");
                                            tmpHistory.push({
                                                "message" : parsedObject['content'],
                                                "from" : parsedObject['role'],
                                                "msgId" : parsedObject['assistantMsgId']
                                            })
                                            
                                            tmpHistory = [...tmpHistory]
                                            
                                            dispatch(setAiChatSelectedSpaceChatHistory(tmpHistory))  
                                        }

                                    break;
                                    case "end":
                                        console.log("END DATA : ", parsedObject) 
                                        dispatch(setLoadingAnswering(false))                                
                                    break;


                                }
                                
                                
                            } catch (error) {
                                console.error("JSON 파싱 에러:", error);
                            }
                            });

                            //var chunkData = JSON.parse(chunk);
                            //console.log(chunkData.content);
                            // 원하는 처리를 수행합니다.
                            return readChunk(); // 다음 chunk를 읽습니다.
                          });
                      };
          
                      return readChunk(); // 첫 번째 chunk를 읽습니다.
                    } else {
                        dispatch(setLoadingWork(false)); // 채팅을 보냈을 때, 로딩값 true로 설정
                      console.error('Invalid response:', response.status);
                    }
                  })
                .catch(error => {
                    console.error('Fetch error:', error);
                    dispatch(setLoadingWork(false)); // 채팅을 보냈을 때, 로딩값 true로 설정
                    /*
                    controller.reason();
                    setTimeout(function(){
                        
                    }, 2000)
                    */
                  
                });
            
         /*
            var config = {
                method: 'post',
                url:  "https://wgcup7amu5uhagh2vvapa7erj40ooeme.lambda-url.ap-northeast-2.on.aws",
                headers: { 
                    'Content-Type': 'application/json'
                },
                responseType: "stream",                
                data : data
            };

   
            axios(config)     
            .then( (response) => {

                console.log(resSetWork)
                if( resSetWork.data.code == 100){
                    // Add New Chat Space
                    if(aiChatSelectedSpace == false) {
                        setNewChatFlag(true)
                        dispatch(setLoadingWork(false)); // 채팅을 보냈을 때, 로딩값 true로 설정
                        var tmpSpaceList = aiChatSpaceList;
                        var newSpaceData = {
                            "appType": "aiChat",
                            "label": resSetWork.data.label,
                            "space_id": resSetWork.data.space_id 
                        }
                        tmpSpaceList.unshift(newSpaceData);
                        tmpSpaceList = [...tmpSpaceList]
                
                        dispatch(setAiChatSpaceList(tmpSpaceList))


                    }
                } 
            }) 
            .catch((error) => {
                console.log(error);
                dispatch(setLoadingWork(false)); // 채팅을 보냈을 때, 로딩값 true로 설정
            });
            */
        }
    }

    return (
        <>
            {
                !loadingSpace && (
                    <div className='wrapper-bottom-inputGroup fr' style={ ( !uiSmallAiBox && uiHideChatSidebar ) ? { width: '100%' } : {} }>
                        {
                            selectedPrompt != false &&
                            <>
                                <div className="box-selected-prompt">
                                    <div className='label-prompt fl'>
                                        {selectedPrompt.label} - {selectedPrompt.desc}
                                    </div>
                                    <div className=' fr' onClick={() => { dispatch(setSelectedPrompt(false)) }}>
                                        <IoIosClose size={"1.5em"} />
                                    </div>          
                                    <div className='cr'></div>                           
                                </div>
                            </>
                        }
                        
                        <div className="input-group">
                            <FormControl
                                className={ uiSmallAiBox ? "input-round chat-input-size" : "chat-input-size" }
                                size="lg"
                                placeholder={ t("aichat.input") }
                                aria-label={ t("aichat.input") }
                                aria-describedby="basic-addon2"
                                onChange={(e) => dispatch( setAiChatMessage(e.target.value) )}
                                // onKeyPress={onKeyUp}
                                onKeyDown={onKeyUp}
                                value={userMessage}
                                disabled={ loadingWork ? true : false }
                                ref={ focusChatInput }
                            />
                            <span className="input-group-btn">
                                { loadingWork != false ? 
                                    <Button className='btn-send-msg' variant='default' disabled>
                                        <ThreeBounce color={ "#808080" }/>
                                    </Button>
                                    : 
                                    <Button 
                                        id="btn-aitutor-send-chat"
                                        className='btn-send-msg' 
                                        variant="default" 
                                        onClick={sendUserMessage}
                                    >
                                        <IoIosSend
                                            id="icon-aitutor-send-chat"
                                        />
                                    </Button>
                                }
                            </span>
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default ChatInputGroup;